import React from "react"


const PostModelViewer = ({
  GLTFMV,
  POSTERMV
}) => {

  const [ modelShow, setModelShow ] = React.useState(false)

  React.useEffect(() => {
    const Model_Viewer_Selector = document.querySelector("model-viewer");
    Model_Viewer_Selector.addEventListener("poster-dismissed", listenIfModelAppeared, { passive: true }); // load model-visibility
    return () => {
      Model_Viewer_Selector.removeEventListener("poster-dismissed", listenIfModelAppeared); // load model-visibility
      setModelShow(false);
    };
  }, [])

  const listenIfModelAppeared = () => {
    setModelShow(true)
  };

  return(
    <model-viewer
      ar ar-modes="webxr scene-viewer quick-look"
      style={{width:"100%", height:"50vh"}}
      environment-image="neutral"
      alt="Your Gift Loading"
      //shadow-intensity="1"
      poster={POSTERMV}
      camera-controls
      loading="eager"
      exposure="1"
      src={GLTFMV}
      enable-pan
    >
      <button className={modelShow ? "ar-button-show" : "ar-button-hide"} slot="ar-button">&#128269; Open Magic Mirror</button>
    </model-viewer>
  )
}

export default PostModelViewer