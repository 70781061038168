import React from "react"
import loadable from "@loadable/component"
import { Link } from "gatsby"
import { kebabCase } from "lodash"
import { RWebShare } from "react-web-share";
import Div100vh from "react-div-100vh"
import Modal from "react-modal"
const Header = loadable(() => import("gatsby-theme-0/src/components/header"))
const Footer = loadable(() => import("gatsby-theme-0/src/components/footer"))
const LeadForm = loadable(() => import("gatsby-theme-0/src/components/leadForm"))
const FooterBar = loadable(() => import("gatsby-theme-0/src/components/footerbar"))
import { PostConfig } from "../config/config-data"
import ExternalScript from "./external-script"
import PostModelViewer from "./post-model-viewer"


const Post = ({
  postSlug,
  postBaseUrl,
  postMain,
  postFrom,
  postTo,
  postName,
  postSlogan,
  postWorth,
  postGLTF,
  postImage,
  postTags,
  postQueryParameter
}) => {

  
  const ReferralCode = new URLSearchParams(postQueryParameter).get('rb');


  const containerRef = React.useRef(null)
  const scrollToNextPart = () => containerRef.current.scrollIntoView()


  const [ ModalState, setModalState ] = React.useState(false);
  const openPurchaseModal = () => {
    setModalState("Purchase");
  };
  const openGiveawayModal = () => {
    setModalState("Giveaway");
  };
  const closeModal = () => {
    setModalState(false);
  };


  React.useEffect(() => {
    if (ModalState) {
      document.body.style.overflow = 'hidden';
    };
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [ModalState]);

  
  return(
    <>
      <ExternalScript />
      <Header />
      <div id="post_1662986307436" className="Post_Page_Hero">
        <div id="post_1662986308965" className="Post_Page_Hero_Overlay">
          {!ModalState && 
          <Div100vh>
            <div id="post_1662986310252" className="Post_Page_Hero_Card">
              <div id="post_1662986311509" style={{justifyContent:"flex-start", alignItems:"center"}} className="Flex_Container">
                <div id="post_1662986312764" style={{textAlign:"left", paddingBottom:"10px"}}>
                  {PostConfig.shop_mode
                  ?
                  <h1 id="post_1662986314645" className="Post_Page_Hero_Heading_Text Heading_Gradient" style={{fontWeight:"bold"}}>We have something really special for you!!!</h1>
                  :
                  <h1 id="post_1662986315845" className="Post_Page_Hero_Heading_Text"><span style={{fontWeight:"bold"}} className="Heading_Gradient">{postName || postTo}</span>, there is a surprise waiting for you!!!</h1>
                  }
                  <h2 id="post_1662986316892" className="Post_Page_Hero_Text">Please scroll below...</h2>
                </div>
              </div>
            </div>
            <div style={{cursor: "pointer"}} id="post_1662986318917" className="Arrow_Down">
              <svg onClick={scrollToNextPart} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M169.4 278.6C175.6 284.9 183.8 288 192 288s16.38-3.125 22.62-9.375l160-160c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0L192 210.8L54.63 73.38c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L169.4 278.6zM329.4 265.4L192 402.8L54.63 265.4c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25l160 160C175.6 476.9 183.8 480 192 480s16.38-3.125 22.62-9.375l160-160c12.5-12.5 12.5-32.75 0-45.25S341.9 252.9 329.4 265.4z"/></svg>
            </div>
          </Div100vh>
          }
        </div>
      </div>
      <div id="post_1662986320220" className="Post_Page_Container" ref={containerRef}>
        <div id="post_1662986326684" className="Post_Page_Card">
          <div id="post_1662986327780" className="Post_Page_Card_Title">{postSlogan}</div>
          <div id="post_1662986328869" className="Post_Page_Card_Flex">
            <div id="post_1662986330260" className="Post_Page_Card_Sticky" style={{width: "50vh"}}>
              <div id="post_1662986331355" style={{height:"50vh"}}>
                <PostModelViewer GLTFMV={postGLTF} POSTERMV={`${postBaseUrl}/assets/mv-poster.svg`} />
              </div>
            </div>
            <div id="post_1662986333675" style={{flex: "clamp(50vh, 20%, 10000px)"}} className="Post_Page_Card_Container">
              {!PostConfig.shop_mode &&
              <em id="post_1662986334763" className="Post_Page_Card_Info">From <span style={{color: "var(--SecondaryBrand)"}}>{postFrom}</span> to <span style={{color: "var(--SecondaryBrand)"}}>{postTo}</span>...</em>
              }
              {PostConfig.post_worth && postWorth &&
              <div id="post_1662986336116" className="Post_Page_Card_Info"><span style={{color: "var(--SecondaryBrand)"}}>Worth:</span> {postWorth}$</div>
              }
              <div id="post_1662986337691" className="Post_Page_Card_Text" dangerouslySetInnerHTML={{ __html: postMain }} />
              <div id="post_1662986339260" className="Buy_Card">
                <div>
                  {!PostConfig.shop_mode &&
                  <div id="post_1662986341404" className="Buy_Text Sub_Heading_Text">Show you appriciation by giving a return gift!!!</div>
                  }
                  <div id="post_1662986343380" className="Buy_Text">Why it's <b>the best gift</b> you can ever give...</div>
                  <ul id="post_1662986345347" className="Buy_List">
                    <li>fuck you</li>
                    <li>fuck you</li>
                    <li>fuck you</li>
                    <li>fuck you</li>
                    <li>fuck you</li>
                    <li>fuck you</li>
                    <li>fuck you</li>
                  </ul>
                  <button onClick={openPurchaseModal}>Buy It</button>
                </div>
                <div id="post_1662986347627" className="Extra_Spacing" />
                <div id="post_1662986349060" className="Extra_Spacing">
                  <div id="post_1662986350403" className="Buy_Text Extra_Spacing">Or win a personalized gift by participating in our giveaway!</div>
                  <button id="post_1662986352067" onClick={openGiveawayModal}>Enter Giveaway</button>
                  <div id="post_1662986353900" className="Extra_Spacing" />
                  <div id="post_1662986355338" className="Normal_Text">According to Mel Robbins, <b>your brain kills a good initiative if you don't work on it within 5 second.</b> If you really want to give your someone a very unique gift that they will remember for forever then count till 5 and click the button above. So, like a rocket launch count 5...4...3...2...1 and click the "Enter Giveaway" button above...</div>
                </div>
              </div>
              {PostConfig.post_tag &&
              <div id="post_1662986357812" className="Post_Page_Card_Tags">
                {postTags.map(tag => (
                  <Link key={tag} style={{marginLeft:"0px"}} className="Custom_Badge" to={`/blog/tag/${kebabCase(tag)}-page-1`}>#{tag}</Link>
                ))}
              </div>
              }
            </div>
          </div>
        </div>
        <div id="post_1662986361659" className="Share_Card">
          {postImage &&
          <div>
            <div id="post_1662986362666" className="Share_Card__Header">
              Let's Pin this image on Pinterest
            </div>
            <div>
              <img id="post_1662986363603" className="Share_Card_Image" src={postImage} alt="#" />
            </div>
          </div>
          }
          <div>
            <div id="post_1662986366315" className="Share_Card__Header">
              Would you like to share it?
            </div>
            <div>
              <div id="post_1662986367554" className="Single_Line_Text_Card">{postBaseUrl}{postSlug}</div>
              <RWebShare
                data={{
                  text: "Check it out!",
                  url: postBaseUrl+postSlug,
                }}
              >
                <button id="post_1662986369378" className="Button_Class_Remover">Let's Share it</button>
              </RWebShare>
            </div>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={ModalState}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={PostConfig.modal_outside_close ? false : true}
        contentLabel="Information Modal"
      >
        <svg className="Modal_Close_Button" alt="Modal Close Button" onClick={closeModal} title="Close Button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
        </svg>
        <LeadForm NextPage={ModalState === "Giveaway" && "/giveaway-last/"} ThePage={postBaseUrl+postSlug} InitialPrice={postWorth} RefID={ReferralCode}/>
      </Modal>
      <FooterBar ModalState={openGiveawayModal} />
      <Footer />
    </>
  )
}

export default Post