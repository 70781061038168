import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo, ProductJsonLd } from "gatsby-plugin-next-seo"
import { SiteMetaConfig } from "gatsby-theme-0/src/config/config-data"
import Post from "./post"
import "./post.css"


const SinglePost = ({ data, location }) => {

  const post = data.markdownRemark

  return (
    <>
      <GatsbySeo
        title={`Surprise for ${post.frontmatter.name || post.frontmatter.to} | ${SiteMetaConfig.site_name}`}
        description={`${post.frontmatter.name || post.frontmatter.to}, there is a surprise waiting for you!!!` || SiteMetaConfig.site_description}
        canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
        openGraph={{
          type: "website",
          url: `${SiteMetaConfig.site_url}${location.pathname}`,
          title: `Surprise for ${post.frontmatter.name || post.frontmatter.to} | ${SiteMetaConfig.site_name}`,
          description: `${post.frontmatter.name || post.frontmatter.to}, there is a surprise waiting for you!!!` || SiteMetaConfig.site_description,
          images: [
            { url: post.frontmatter.image || SiteMetaConfig.site_image },
          ],
          site_name: SiteMetaConfig.site_name,
        }}
        twitter={{
          handle: `@${SiteMetaConfig.site_twitter_handle}`,
          site: `@${SiteMetaConfig.site_name}`,
          cardType: "summary_large_image",
        }}
        facebook={{
          appId: SiteMetaConfig.site_facebook_app_id,
        }}
        htmlAttributes={{
          lang: SiteMetaConfig.site_language,
        }}
        noindex={post.frontmatter.noindex ? true : false}
        nofollow={post.frontmatter.noindex ? true : false}
      />
      <ProductJsonLd
        productName={`Surprise gift for ${post.frontmatter.name || post.frontmatter.to} | ${SiteMetaConfig.site_name}`}
        images={[
          { url: post.frontmatter.image || SiteMetaConfig.site_image },
        ]}
        description="A thoughtful personalized gift for loved one's, friends and family"
        brand={SiteMetaConfig.site_name}
        aggregateRating={{
          ratingValue: '4.7',
          reviewCount: '941',
        }}
        offers={{
          price: post.frontmatter.worth,
          priceCurrency: 'USD',
          itemCondition: 'https://schema.org/NewCondition',
          availability: 'http://schema.org/InStock',
          url: `${SiteMetaConfig.site_url}${location.pathname}`,
          seller: {
            name: SiteMetaConfig.site_name,
          },
        }}
        mpn={location.pathname}
      />
      <Post
        postNoIndex={post.frontmatter.noindex}
        postSlug={location.pathname}
        postBaseUrl={SiteMetaConfig.site_url}
        postMain={data.markdownRemark.html}
        postFrom={post.frontmatter.from}
        postTo={post.frontmatter.to}
        postName={post.frontmatter.name}
        postSlogan={post.frontmatter.slogan}
        postWorth={post.frontmatter.worth}
        postGLTF={post.frontmatter.gltf}
        postImage={post.frontmatter.image}
        postTags={post.frontmatter.tags}
        postQueryParameter={location.search}
      />
    </>
  )
}

export const singlePostQuery = graphql`
  query singlePostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        noindex
        from
        to
        name
        slogan
        worth
        gltf
        image
        tags
      }
    }
  }
`

export default SinglePost